@layer modules, ui, base;
@layer base {
  .page_goBack__4qpha {
  align-self: flex-start;
}

.page_main__Hrev4 {
  display: flex;
  flex-direction: column;
}

.page_aside__RammA {
  display: none;
}

.page_title__7a11K {
  margin-bottom: var(--sk-space-16);
  padding: var(--sk-space-24) var(--sk-space-16);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

.page_moduleSection__pmMKj {
  padding: var(--sk-space-32) var(--sk-space-16);
  background-color: var(--sk-color-white);
}

.page_unitHeader__eN_WU {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sk-space-24);
  border-bottom: thin solid var(--sk-color-grey-200);
  gap: var(--sk-space-8);
}

.page_stepper__hjymw {
  display: flex;
  gap: var(--sk-space-8);
}

.page_stepperItem__PuoD1 {
  width: 20px;
  height: 20px;
  color: var(--sk-color-grey-500);
}

.page_stepperItemIcon__uWv4W {
  width: 20px;
  height: 20px;
  color: var(--sk-color-black);
}

.page_unitNavigation__0_Onu {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-64);
  gap: var(--sk-space-40);
}

.page_unitCompletion__udE6_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sk-space-8);
  justify-content: center;
  margin-top: var(--sk-space-64);
}

.page_completionQuizzTip__EQ2iu {
  display: flex;
  color: var(--sk-color-warning-500);
  text-align: center;
  gap: var(--sk-space-4);
}

@media (min-width: 768px) {
  .page_title__7a11K {
    padding: var(--sk-space-32) var(--sk-space-40);
    border-radius: var(--sk-radius-16);
  }

  .page_moduleSection__pmMKj {
    padding: var(--sk-space-32) var(--sk-space-72);
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .page_unitHeader__eN_WU {
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media (min-width: 1312px) {
  .page_main__Hrev4 {
    flex-direction: row;
    gap: var(--sk-space-8);
  }

  .page_content__tKO92 {
    flex: 1;
    width: calc(100vw - 496px);
  }

  /* Aside Begin */

  .page_aside__RammA {
    display: block;
    width: 312px;
    padding: var(--sk-space-24);
  }

  .page_asideHeader__mC0yx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sk-space-16);
    gap: var(--sk-space-24);
  }

  .page_asideProgressBar__MlfXn {
    width: 100%;
    height: var(--sk-space-4);
    margin-bottom: var(--sk-space-40);
    border-radius: var(--sk-radius-12);
    background-color: var(--sk-color-grey-100);
  }

  .page_asideProgress__WiMEt {
    height: 100%;
    background-color: var(--sk-color-black);
  }

  .page_sequencesList__ip4Zs {
    display: flex;
    flex-direction: column;
    gap: var(--sk-space-20);
    padding: 0;
    list-style-type: none;
  }

  .page_sequenceItemLink__x17VM {
    display: flex;
    gap: var(--sk-space-8);
    color: var(--sk-color-black);
    font-size: var(--sk-typography-body-2-regular-font-size);
    text-decoration: none;
  }

  .page_sequenceItemLinkComplete___GPyu {
    color: var(--sk-color-grey-600);
  }

  .page_sequenceItemLinkCurrent__dG_S1 {
    color: var(--sk-color-purple-500);
    font-weight: var(--sk-typography-body-2-semibold-font-weight);
  }

  .page_sequenceItemLinkDefault__vxUst {
    margin-left: calc(var(--sk-space-24) + var(--sk-space-8));
  }

  .page_sequenceItemTitle__4ODAh {
    flex: 1;
  }

  /* Aside End */
}

}
@layer ui {
  /** @define Spinner */

.Spinner_jds-Spinner__qSvCy {
  width: 1.25rem;
  height: 1.25rem;
  animation: Spinner_spinner__z_cy6 2s linear infinite;
  stroke-width: 0.6rem;
  color: currentcolor;
  vertical-align: middle;
}

.Spinner_jds-Spinner--minor__v4Zv_ {
  width: 1rem;
  height: 1rem;
}

.Spinner_jds-Spinner--ghost__g_jYG {
  color: var(--color-black);
}

@keyframes Spinner_spinner__z_cy6 {
  50% {
    transform: rotate(360deg);
    stroke-dashoffset: 20;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 243.4675;
  }
}

}
@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.Loading_sha-Loading__tmLTe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Loading_sha-Loading__spinner--isOnWhiteBackground__pHKQ_ {
  stop-color: var(--mainColor);
}

}
@layer base {
  .LoadingSection_main__DmYGj {
  display: flex;
  flex: 1;
}

}
@layer base {
  .UnitIframe_main__lq8Ck {
  display: block;
  /* Override the main html element in iframe with padding */
  width: calc(100% + 20px);
  /* Override the main html element in iframe with padding */
  margin: 0 -20px;
  border: none;
}

.UnitIframe_mainLoading__q9BKY {
  display: none;
}

.UnitIframe_loader__2Oxlp {
  height: 100vh;
}

@media (min-width: 64rem) {
  /* Override the main html element in iframe with padding */
  .UnitIframe_main__lq8Ck {
    width: calc(100% + 40px);
  }
}

}
@layer base {
  /** @define Error */
/* stylelint-disable plugin/selector-bem-pattern */

.StandardError_wrapper___WScW {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 3em;
}

.StandardError_main__o_GST {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: var(--sk-space-32);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-space-8);
}

.StandardError_content__wvNn4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--sk-space-32);
}

.StandardError_illustration__itwwh {
  align-self: center;
  width: 100%;
  max-width: 300px;
}

.StandardError_illustration__itwwh img {
  width: 100%;
  height: auto;
}

.StandardError_errorId__303Kq {
  font-style: italic;
  text-align: right;
}

@media (min-width: 48rem) {
  .StandardError_main__o_GST {
    flex-direction: row;
  }

  .StandardError_content__wvNn4 {
    flex: 3 1 0;
    margin-right: var(--sk-space-32);
    margin-bottom: 0;
  }

  .StandardError_illustration__itwwh img {
    flex: 2 1 0;
    width: auto;
  }

  .StandardError_errorId__303Kq {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
  }
}

@media (min-width: 64rem) {
  .StandardError_main__o_GST {
    max-width: 75%;
    margin: 0 auto;
  }
}

}
@layer base {
  .page_goBack__VTaVh {
  align-self: flex-start;
}

.page_main__cqqMX {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-40);
  padding: 0 var(--sk-space-16);
}

.page_blockButton___iWVW {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .page_main__cqqMX {
    padding: 0;
  }

  .page_blockButton___iWVW {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

}
@layer base {
  .ApplicationFlow_main__YiWlg {
  --form-transform: translateX(0);

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--sk-color-white);
}

.ApplicationFlow_header__CwaJU {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--sk-space-24) var(--sk-space-24) 0;
}

.ApplicationFlow_isLoading__t1eWc {
  pointer-events: none;
}

.ApplicationFlow_closeButton__pz_2_ {
  margin-left: auto;
}

.ApplicationFlow_content__4Utq2 {
  flex-grow: 1; /* Take all the remaining height */
  padding: var(--sk-space-24) var(--sk-space-24) 0;
  overflow: hidden auto; /* Make vertical content scrollable */
}

/**
 * The layout make twice the width of the container and translate it to the left.
 */
.ApplicationFlow_content--twoCols__xgmDz {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  width: 200%;
  transform: var(--form-transform);
  transition: transform 300ms ease-in-out;
  gap: var(--sk-space-48);
}

.ApplicationFlow_footer__Aup2i {
  padding: var(--sk-space-24);
  background-color: var(--sk-color-white);
  /* shadow is --sk-color-grey-600 with 15% opacity */
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(100 96 108 / 15%);
}

.ApplicationFlow_button__1QnWh {
  width: 100%;
}

.ApplicationFlow_form__cGY_S {
  height: -moz-fit-content;
  height: fit-content;
}

.ApplicationFlow_form--hidden__DpDh8 {
  visibility: hidden;
  pointer-events: none;
}

@media (min-width: 1312px) {
  .ApplicationFlow_main__YiWlg {
    position: sticky;
    height: calc(100vh - var(--distance-to-top, 0) - var(--sk-space-24) * 2);
    margin: var(--sk-space-24) 0;
    overflow: hidden auto;
    transition: height 0.2s;
    border-radius: var(--sk-radius-24);
    box-shadow: 0 var(--sk-space-2) var(--sk-space-24) 0 rgb(0 0 0 / 16%);
    inset: var(--sk-space-24) 0;
  }

  .ApplicationFlow_heading___success__3s5yQ {
    display: none;
  }
}

}
@layer base {
  .UploadFile_container__TsZsF {
  display: none;
}

.UploadFile_container___visible__pDrwM {
  display: block;
}

.UploadFile_main__5MeOi {
  display: none;
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
  text-align: center;
}

.UploadFile_main___visible__7q9kV {
  display: block;
}

.UploadFile_main___dragOver__15kn8 {
  background-color: var(--sk-color-grey-100);
}

.UploadFile_overtitle__HJOjo {
  margin-bottom: var(--sk-space-16);
}

.UploadFile_icon__OuSn0 {
  margin-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-100);
}

.UploadFile_fileInfo__N32ex {
  margin-top: var(--sk-space-24);
  color: var(--sk-color-grey-500);
}

.UploadFile_description__fPb2M {
  margin-top: var(--sk-space-4);
}

.UploadFile_uploadButton__c_ODS {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-16);
}

/* This prevents to drop a file on the button */
.UploadFile_main___dragOver__15kn8 .UploadFile_uploadButton__c_ODS {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.UploadFile_uploadButton__input__0icHO {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.UploadFile_error__WgfNl {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

}
@layer base {
  .CvStep_header__sXUxx {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.CvStep_content__Vzu3U {
  padding-bottom: var(--sk-space-40);
}

.CvStep_footer___HDQF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--sk-space-24);
}

.CvStep_footer__button__6rf_8 {
  flex: 1 1 auto;
}

.CvStep_footer___HDQF input[type="file"] {
  display: none;
}

@media (max-width: 767px) {
  .CvStep_footer___HDQF {
    display: flex;
    position: absolute;
    right: var(--sk-space-16);
    bottom: var(--sk-space-40);
    left: var(--sk-space-16);
    flex-direction: column;
    gap: var(--sk-space-16);
  }
}

.CvStep_cvHelpText__qquA1 {
  margin-top: var(--sk-space-16);
  color: var(--sk-color-grey-600);
}

}
@layer base {
  .CvBox_clickableCard___h8je {
  border-color: transparent !important;
}

.CvBox_uploadButton__WnLc3 {
  width: auto;
}

.CvBox_footer__QRbn6 {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.CvBox_footer__QRbn6 input[type="file"] {
  display: none;
}

}
@layer base {
  .Page_main__uXvqu {
  --local-row-gap: var(--sk-space-24);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

.Page_mainContent__cao5N {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--sk-space-24);
}

@media (min-width: 768px) {
  .Page_main__uXvqu {
    padding-top: var(--sk-space-64);
    padding-bottom: var(--sk-space-64);
  }

  .Page_aside__McIFH {
    margin-right: var(--sk-space-16);
  }
}

}
@layer base {
  .CoverLetterContent_messageField__xbRht {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: 1px solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  resize: vertical;
}

.CoverLetterContent_messageField__xbRht:focus {
  outline: 2px solid var(--sk-color-grey-500);
  outline-offset: -1px;
}

.CoverLetterContent_messageLabel__NdxVy {
  margin: var(--sk-space-16) 0 var(--sk-space-4);
}

.CoverLetterContent_messageLength__7kB5u {
  color: var(--sk-color-grey-500);
  text-align: end;
}

.CoverLetterContent_error__fV6op {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.CoverLetterContent_warning__pQZaE {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-warning-500);
}

}
@layer ui {
  .sk-Radio_radioWrapper__VAn8i {
  position: relative;
}

.sk-Radio_main__lxi9G {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-12);
  transition: background-color 100ms ease-out;
  border-radius: var(--sk-form-radio-radius);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Radio_main--highlighted__qfqjX {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--sk-space-16);
  border: solid var(--sk-border-width-2);
  gap: var(--sk-space-16);
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--highlighted__qfqjX {
  border-color: var(--sk-form-radio-color-border-highlighted-unchecked-light);
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--highlighted__qfqjX {
  border-color: var(--sk-form-radio-color-border-highlighted-unchecked-dark);
}

input:checked + .sk-Radio_main--light__WgOWW.sk-Radio_main--highlighted__qfqjX:not(.sk-Radio_main--disabled__u_dXa) {
  border-color: var(--sk-form-radio-color-border-highlighted-checked-light);
}

input:checked + .sk-Radio_main--light__WgOWW.sk-Radio_dark--highlighted__reh2C:not(.sk-Radio_main--disabled__u_dXa) {
  border-color: var(--sk-form-radio-color-border-highlighted-checked-dark);
}

.sk-Radio_hiddenRadioInput__XQh2f {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.sk-Radio_radio__O_M_P {
  display: inline-flex;
  box-sizing: border-box;
  flex-shrink: 0;
  place-items: center center;
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  margin: var(--sk-space-2) 0;
  border-radius: var(--sk-form-radio-input-radius);
}

.sk-Radio_label__CwO9w {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-Radio_main--highlighted__qfqjX .sk-Radio_label__CwO9w {
  font-family: var(--sk-typography-body-1-regular-font-family);
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.sk-Radio_main--disabled__u_dXa {
  cursor: not-allowed;
}

.sk-Radio_main--light__WgOWW {
  color: var(--sk-form-radio-color-default-light);
}

.sk-Radio_main--dark__AkgrO {
  color: var(--sk-form-radio-color-default-dark);
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) {
  background-color: var(--sk-form-radio-color-background-hover-light);
  color: var(--sk-form-radio-color-hover-light);
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa) {
  background-color: var(--sk-form-radio-color-background-hover-dark);
  color: var(--sk-form-radio-color-hover-dark);
}

input:focus-visible + .sk-Radio_main--light__WgOWW {
  background-color: var(--sk-form-radio-color-background-focus-light);
  color: var(--sk-form-radio-color-focus-light);
}

input:focus-visible + .sk-Radio_main--dark__AkgrO {
  background-color: var(--sk-form-radio-color-background-focus-dark);
  color: var(--sk-form-radio-color-focus-dark);
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--disabled__u_dXa {
  color: var(--sk-form-radio-color-disabled-light);
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--disabled__u_dXa {
  color: var(--sk-form-radio-color-disabled-dark);
}

input:checked + .sk-Radio_main__lxi9G .sk-Radio_radio__O_M_P::after {
  content: "";
  display: flex;
  flex-shrink: 0;
  width: var(--sk-size-12);
  height: var(--sk-size-12);
  margin: 0 auto;
  animation: sk-Radio_expandCircle__GxXyw 100ms forwards;
  border-radius: 50%;
  opacity: 0;
}

input:focus-visible + .sk-Radio_main__lxi9G {
  outline: none;
  box-shadow: var(--sk-shadow-focus-ring-light-shadow-3), var(--sk-shadow-focus-ring-light-shadow-2),
    var(--sk-shadow-focus-ring-light-shadow-1);
}

.sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P {
  border: var(--sk-size-2) solid var(--sk-form-radio-input-color-border-default-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P {
  border: var(--sk-size-2) solid var(--sk-form-radio-input-color-border-default-dark);
  background-color: transparent;
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-hover-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-hover-dark);
  background-color: transparent;
}

input:focus-visible + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-focus-light);
  background-color: transparent;
}

input:focus-visible + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-focus-dark);
  background-color: transparent;
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--disabled__u_dXa .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-disabled-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--disabled__u_dXa .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-disabled-dark);
  background-color: transparent;
}

input:checked + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-default-light);
}

input:checked + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-default-dark);
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-hover-light);
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa).sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-hover-dark);
}

input:focus-visible + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-focus-light);
}

input:focus-visible + .sk-Radio_main--dark__AkgrO.sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-focus-dark);
}

input:checked:disabled + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-disabled-light);
}

input:checked:disabled + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-disabled-dark);
}

@keyframes sk-Radio_expandCircle__GxXyw {
  from {
    transform: scale(0.2);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

}
@layer ui {
  .sk-RadioGroup_main__D6VW3 {
  display: flex;
  flex-direction: column;
}

.sk-RadioGroup_main--highlighted__2jsSc {
  gap: var(--sk-space-8);
}

.sk-RadioGroup_main__D6VW3 > :first-child {
  margin-bottom: var(--sk-space-8);
}

.sk-RadioGroup_errorMessage__UBPUC {
  padding: var(--sk-space-2) var(--sk-space-12);
  color: var(--sk-color-error-500);
}

.sk-RadioGroup_main--light__f3_IH {
  color: var(--sk-form-radio-color-default-light);
}

.sk-RadioGroup_main--dark__egxe6 {
  color: var(--sk-form-radio-color-default-dark);
}

}
@layer base {
  .BooleanQuestion_fieldWrapper__cxTvM {
  /* override component css */
  min-height: auto;
}

.BooleanQuestion_booleanField__nSu4m + .BooleanQuestion_booleanField__nSu4m {
  margin-left: var(--sk-space-24);
}

@media (min-width: 1312px) {
  .BooleanQuestion_booleanField__nSu4m + .BooleanQuestion_booleanField__nSu4m {
    margin-left: var(--sk-space-56);
  }
}

}
@layer base {
  .FileInput_main__VSeMh {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.FileInput_label__Qnvlj {
  text-align: left;
}

.FileInput_content__VJvTu,
.FileInput_previewWrapper__vqjDe {
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

/* Field */
.FileInput_field__EaVg9 {
  display: none;
  text-align: center;
}

.FileInput_field___visible__z3Nfs {
  display: block;
}

.FileInput_field___dragOver__8gjL_ {
  background-color: var(--sk-color-grey-100);
}

.FileInput_field__EaVg9 .FileInput_notice__SzB16 {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-grey-600);
}

.FileInput_field__EaVg9 .FileInput_error__j_LnS {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.FileInput_field__EaVg9 .FileInput_uploadButton__xGE5n {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-24);
}

/* This prevents to drop a file on the button */
.FileInput_field___dragOver__8gjL_ .FileInput_uploadButton__xGE5n {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.FileInput_field__EaVg9 .FileInput_uploadButton__input__Ceu6b {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* Preview */
.FileInput_preview__yFNBs {
  display: flex;
  align-items: center;
  outline: none;
  color: inherit;
  text-decoration: none;
}

.FileInput_content__VJvTu > .FileInput_previewWrapper__vqjDe {
  position: initial;
  padding: 0;
}

.FileInput_preview__yFNBs::after {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
}

.FileInput_preview__yFNBs:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.FileInput_preview__yFNBs:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.FileInput_preview__yFNBs .FileInput_description__ETdhG {
  margin-right: auto;
}

.FileInput_preview__yFNBs .FileInput_actions__lJIpB {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
}

.FileInput_preview__yFNBs .FileInput_deleteButton__PLCU_ {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  color: var(--sk-color-error-500);
}

}
@layer base {
  .FileQuestion_main__5sldR {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

}
@layer ui {
  .sk-InputWrapper_main__1zVBN {
  display: flex;
  flex-direction: column;
  font-family: var(--sk-font-families-poppins);
}

.sk-InputWrapper_labelWrapper__RFcXM {
  display: flex;
  gap: var(--sk-space-4);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.sk-InputWrapper_labelWrapper--large__dv8Ff {
  margin-bottom: var(--sk-space-12);
}

.sk-InputWrapper_subLabel___4Fiu {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_messageWrapper__BsKEb {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-8);
  gap: var(--sk-space-8);
}

.sk-InputWrapper_message__4RFam,
.sk-InputWrapper_secondaryMessage__UwXuM {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_secondaryMessage__UwXuM {
  margin-left: auto;
}

}
@layer base {
  .SvgImage_main__oZ4ze {
  /* 1x1 pixel blank transparent GIF image */
  content: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  background-color: currentcolor;
}

}
@layer base {
  .RatingInput_main__NgMba {
  --local-icon-size: var(--sk-space-24);
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.RatingInput_control__RKc6D {
  display: flex;
  gap: var(--sk-space-4);
}

.RatingInput_controlLabel__hGgwv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.RatingInput_label__0pGWP {
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  overflow: hidden;
  cursor: pointer;
}

.RatingInput_input__EY0n9 {
  position: absolute;
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  margin: 0;
  padding: 0;
  opacity: 0;
}

}
@layer base {
  .PublicationDateFilterShortcut_main__oJ4ny {
  position: relative;
}

@media (max-width: 767px) {
  .PublicationDateFilterShortcut_main__oJ4ny {
    display: none;
  }
}

.PublicationDateFilterShortcut_dropdown__jMS_l {
  padding: var(--sk-space-4) var(--sk-space-8);
}

}
@layer base {
  .ResultsSort_main__T1eJo {
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the job ad cards
  */
  position: relative;
  z-index: var(--z-above-ui-kit-cards);
}

@media (max-width: 767px) {
  /* Make it a square button with no extra space */
  .ResultsSort_button__6PeyO {
    width: var(--sk-size-48);
    height: var(--sk-size-48);
    padding-right: var(--sk-Button-x-padding);
  }
}

@media (min-width: 768px) {
  /* Display the label of the button */
  .ResultsSort_button__6PeyO::after {
    content: attr(aria-label);
  }
}

.ResultsSort_button___expanded__BUuzY {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
}

.ResultsSort_options__HWPlp {
  position: absolute;
  right: 0;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-white);
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
}

.ResultsSort_options__HWPlp label {
  white-space: nowrap;
}

}
@layer base {
  .RssFeedModal_button__14UF6 {
  margin-left: var(--sk-space-4);
}

}
@layer base {
  .RssFeed_main__3nlsA {
  margin-top: var(--sk-space-16);
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  background: var(--sk-color-white);
}

.RssFeed_header__3hNB6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RssFeed_button__4Gvkl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--sk-space-8);
}

.RssFeed_error__mAdGl {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.RssFeed_field__GZm3A {
  margin-top: var(--sk-space-16);
  margin-bottom: var(--sk-space-16);
}

.RssFeed_field__GZm3A input {
  min-width: 0;
}

}
@layer base {
  /* Main */
.SecondaryFilters_main__IUPre {
  width: 100%;
}

@media (min-width: 768px) {
  .SecondaryFilters_main__IUPre {
    width: auto;
  }
}

/* Actions */
.SecondaryFilters_actions__XAKs8 {
  display: grid;
  justify-items: center;
}

@media (min-width: 768px) {
  .SecondaryFilters_actions__XAKs8 {
    justify-items: flex-start;
  }
}

/* Button */

@media (max-width: 767px) {
  .SecondaryFilters_button__UZTC_ {
    width: 100%;
  }
}

}
@layer base {
  .SecondaryFiltersBar_main__1PyDK {
  --gap-size: var(--sk-space-8);
  display: flex;
  gap: var(--gap-size);
}

.SecondaryFiltersBar_divider__dDszn {
  align-self: center;
  height: var(--sk-space-24);
  transform: scaleY(50%);
}

.SecondaryFiltersBar_divider__dDszn,
.SecondaryFiltersBar_shortcutFilter__rP2XA {
  display: none;
}

@media (max-width: 767px) {
  .SecondaryFiltersBar_saveSearchButton__r5sMf {
    display: none;
  }
}

.SecondaryFiltersBar_saveSearchButton__r5sMf {
  margin-left: auto;
}

@media (min-width: 1312px) {
  .SecondaryFiltersBar_divider__dDszn,
  .SecondaryFiltersBar_shortcutFilter__rP2XA {
    display: initial;
  }
}

}
@layer base {
  .Form_formStep__gTkns {
  display: none;
  flex-direction: column;
  gap: var(--sk-space-32);
}

.Form_formStep__visible__YyAEX {
  display: flex;
}

.Form_formStep__gTkns > .Form_title__glRFJ {
  margin-bottom: var(--sk-space-16);
}

.Form_fields__OHvuN {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.Form_controls__inline___rUIa {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-8);
}

.Form_control__enlarge__Sjboe {
  flex: 1;
}

.Form_control__tooltip__9cxLe {
  align-self: flex-end;
  margin-bottom: var(--sk-space-12);
}

@media (min-width: 768px) {
  .Form_ratingInput__HEHnH {
    flex-direction: row;
    justify-content: space-between;
  }
}

.Form_actions___n46h {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--sk-space-40);
  gap: var(--sk-space-24);
}

@media (max-width: 767px) {
  .Form_actions___n46h {
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .Form_action__iVWAj {
    width: 100%;
  }
}

}
